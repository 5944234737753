import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import ScrollProgressRead from "react-scroll-progress-read"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import AuthorInfo from "../../components/author-info/author-info"

import ServicesList from "../../components/services-list/services-list"
import BlogList from "../../components/blog-list/blog-list"

import "../../styles/blog.scss"

const AdministratorPage = () => {
  const imageArticle = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "blog-curse-of-the-nagas.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="സർപ്പശാപ ദുരിതമെങ്ങിനെ അകറ്റാം? – Astrologer Aravind | Ashtamangalaprasnam Blog"
        description="ഹൈന്ദവ വിശ്വാസം അനുസരിച്ച് ഏറ്റവും ദൈവിക ജീവികളാണ് നാഗങ്ങൾ. അവരുടെ ദൈവത്വത്തെക്കുറിച്ചും അവരുടെ ശാപത്തിന്റെ ശക്തിയെക്കുറിച്ചുമുള്ള കഥകൾ സത്യമോ മിഥ്യയോ?"
        keyword="sarpa, dosha, sarppa dosham, nagadosham, sarppadosham, naga curse, hinduism, nagadeva, naga"
        image="../../images/blog-curse-of-the-nagas.jpg"
      />
      <div className="scroll-progress">
        <ScrollProgressRead
          backgroundColor="#CCC"
          barColor="#931a1a"
          height="5px"
          target="article"
        />
      </div>
      <section id="article" className="article">
        <section className="article-header malayalam">
          <Link
            className="button article-header__button serif-font"
            to="../curse-of-the-nagas"
            title="The Curse of the Nagas"
          >
            English
          </Link>
          <Image
            className="article-header__image"
            fluid={imageArticle.file.childImageSharp.fluid}
            alt="Naga idols in Sarppakkavu"
          />
          <div className="article-header__text-wrap">
            <h1 className="article__heading">
              സർപ്പശാപ ദുരിതമെങ്ങിനെ അകറ്റാം?
            </h1>
            <p className="article__description">
              ഹൈന്ദവ വിശ്വാസം അനുസരിച്ച് ഏറ്റവും ദൈവിക ജീവികളാണ് നാഗങ്ങൾ. അവരുടെ
              ദൈവത്വത്തെക്കുറിച്ചും അവരുടെ ശാപത്തിന്റെ ശക്തിയെക്കുറിച്ചുമുള്ള
              കഥകൾ സത്യമോ മിഥ്യയോ?
            </p>
          </div>
        </section>
        <section className="container article-wrap">
          <article className="article__content malayalam">
            <p>ശാപങ്ങൾ പലതുണ്ട്, ഒരു പഴയ ശ്ലോകത്തിൽ ഇങ്ങിനെ പറയുന്നു,</p>

            <blockquote>
              "ഗുരു ദേവ ദ്വിജഃ അത്യുഗ്ര
              <br />
              സർപ്പശാപാപിചാരത
              <br />
              ജാതകം ഭിദ്യതേ ന്യൂനം
              <br />
              നോ ചേതത്തൽ പ്രതിക്രിയാ"
            </blockquote>

            <p>
              ഗുരു, ദേവൻ, ബ്രാഹ്മണൻ, ആഭിചാരം, സർപ്പശാപം മുതലായവ ഉള്ളവർക്ക്
              ജാതകത്തിൽ എത്ര യോഗങ്ങളുണ്ടായാലും എത്ര നല്ല ദശ വന്നാലും
              അനുഭവയോഗ്യമാവുകയില്ല. ഈ പഞ്ച വിധ ദോഷങ്ങളിൽ വെച്ച് ഏറ്റവും
              പ്രാധാന്യം ഉള്ളത് സർപ്പശാപ ദുരിതമാണ്.
            </p>

            <p>
              സർപ്പങ്ങൾ ഭാരതീയ പാരമ്പര്യത്തിൽ പ്രധാന പങ്കുവഹിക്കുന്നു, മനുഷ്യർ
              ജീവിതത്തിൽ ഉയർന്ന നിലയിലെത്താൻ ആഗ്രഹിക്കുന്നു. ആഗ്രഹത്തെയാണ്
              സർപ്പത്തോട് ഉപമിക്കുന്നത്. സർപ്പം ജീവശക്തി മുകളിലേക്ക് സഞ്ചരിച്ചു
              സഹസ്രഹാര ചക്രത്തെ ഉദ്യമിപ്പിക്കുന്ന കുണ്ഡലിനീ ശക്തിയുടെ പ്രതീകം
              സർപ്പമാണ്.
            </p>

            <p>
              പാശ്ചാത്യര്&zwj; സര്&zwj;പ്പങ്ങളെ ശത്രുക്കളായി കരുതുന്നു. കാരണം
              പ്രപഞ്ചത്തിലെ ആദ്യത്തെ സ്&zwnj;ത്രീയായ ഹവ്വയെ തിന്മയുടെ കനിയായ
              ആപ്പിള്&zwj; കഴിക്കാന്&zwj; പ്രേരിപ്പിച്ചത്&zwnj;
              സര്&zwj;പ്പമാണെന്ന്&zwj; അവര്&zwj; വിശ്വസിക്കുന്നു. ആദാമിനും
              ഹവ്വക്കും ഇടയില്&zwj; ദാമ്പത്യ ജീവിതം ഉണ്ടായിരുന്നില്ല.
              സര്&zwj;പ്പം ബുദ്ധിശാലിയായ ഹവ്വയെ ആപ്പിള്&zwj; കഴിക്കാന്&zwj;
              പ്രേരിപ്പിച്ചു. മനുഷ്യവര്&zwj;ഗത്തിന്&zwnj; വിത്തുപാകിയത്&zwnj;
              അപ്പോഴാണ്&zwnj;. അതുകൊണ്ട്&zwnj; ഭൂമിയില്&zwj; ജീവന്&zwj;
              ഉണ്ടാക്കിയത്&zwnj; ഈശ്വരനാണെന്നു സമ്മതിക്കുന്നുവെങ്കില്&zwj; അതു
              വര്&zwj;ദ്ധിക്കുവാന്&zwj; സഹായിച്ചത്&zwnj; സര്&zwj;പ്പമാണ്&zwnj;
              എന്നു സമ്മതിക്കണമല്ലോ. ഈ രീതിയില്&zwj; നോക്കുമ്പോള്&zwj;
              സര്&zwj;പ്പം ഈശ്വരന്&zwj;റെ ദൂതനാണ്&zwnj; എന്നു വേണം കരുതാന്&zwj;.
              ജീവിതത്തിന്&zwj;റെ അടിസ്ഥാന സ്വഭാവം മനസ്സിലാക്കാത്തവര്&zwj;
              സര്&zwj;പ്പത്തെ ചെകുത്താന്&zwj;റെ ആളായിട്ടു കരുതുന്നു.
              സര്&zwj;പ്പത്തെ വിശുദ്ധ ജീവിയായി കരുതുന്നത്&zwnj; ഭാരത
              പാരമ്പര്യമാണ്&zwnj;. അതുകൊണ്ടാണ്&zwnj; ഭാരതത്തിലെ
              ക്ഷേത്രങ്ങളില്&zwj; സര്&zwj;പ്പ ശില്&zwj;പങ്ങളുള്ളത്&zwnj;.
              മനുഷ്യവര്&zwj;ഗത്തിന്&zwj;റെ പരിണാമ വളര്&zwj;ച്ചയില്&zwj;
              സര്&zwj;പ്പത്തിനു മുഖ്യപങ്കുണ്ട്&zwnj;. അതുകൊണ്ട്&zwnj; പാമ്പുകളെ
              ചെകുത്താന്&zwj;റെ ദൂതന്മാര്&zwj; എന്നു പറയുന്നത്&zwnj;
              അജ്ഞാനമാണ്&zwnj;. മനുഷ്യ വംശത്തില്&zwj;
              വര്&zwj;ദ്ധനവുണ്ടാകണമെന്ന്&zwj; ഈശ്വരന്&zwj;
              കരുതിയിരുന്നില്ലെങ്കില്&zwj; സര്&zwj;പ്പത്തെക്കൊണ്ട്&zwnj; ഹവ്വയെ
              ആപ്പിള്&zwj; കഴിക്കാന്&zwj; പ്രേരിപ്പിക്കില്ലായിരുന്നു. അങ്ങനെ,
              ജീവിതം തന്നെ ഈശ്വരന്&zwj;റെ സൃഷ്&zwnj;ടിയാണെങ്കില്&zwj;
              സര്&zwj;പ്പം ഈശ്വരന്&zwj;റെ ദൂതനാണ്&zwnj;”.
            </p>

            <p>
              ഈ ദൂതന്മാർ അധിവസിക്കുന്ന ഭൂമി പരശുരാമന്റെ അപേക്ഷപ്രകാരം തങ്ങളുടെ
              ഭൂമി മനുഷ്യർക്കധിവാസിക്കാനായി ചില നിബന്ധനകളോടെ വിട്ടുനല്കിയത്. അത്
              നമ്മുടെ മുത്തശ്ശന്മാരുടെ കാലഘട്ടം വരെ സർപ്പക്കാവുകളായും
              സർപ്പകുളങ്ങളായും സർപ്പാതറയായും പല രൂപങ്ങളിൽ നിലനിന്നു
              പോന്നിരുന്നു. അതവർക്ക് തലമുറകൾ നിലനിർത്താൻ ഊർജ്ജശ്രോതസ്സായി
              പരിണമിച്ചിരുന്നു. സന്താനദുരിതത്തിന്റെ ദുരിതം പേറുന്നവരുടെ
              കുടുംബങ്ങൾ സർപ്പക്കാവുകൾ ഉപേക്ഷിച്ചവരോ നശിപ്പിച്ചവരോ
              ആയിരിക്കുമെന്നത് തീർച്ചയാണ്.
            </p>

            <p>
              സര്&zwj;പ്പങ്ങള്&zwj; എങ്ങനെ ശപിക്കും?
              <br />
              ശാപം എന്നാല്&zwj; ഒരു തരം പ്രതി പ്രവര്&zwj;ത്തനം ആണ് . മനുഷ്യ൯
              ഉള്&zwj;പ്പെടെയുള്ള എല്ലാ ജീവജാലങ്ങളിലും, ഈ പ്രതി പ്രവര്&zwj;ത്തനം
              ഉണ്ട്. നമ്മുടെ മനസ്സിനെ നിരന്തരമായി വിഷമിപ്പിക്കുന്ന
              ഒരാള്&zwj;ക്കെതിരെ, നമ്മുടെ മനസ്സിലുണ്ടാകുന്ന, വിദ്വേഷം ഒരു
              നെഗറ്റിവ്വ് ഊര്&zwj;ജ്ജമായി രൂപപ്പെടുകയും, അത് മറ്റേ വ്യക്തിയെ
              പ്രതികൂലമായി ബാധിക്കുകയും ചെയ്യും.
            </p>

            <p>
              മനുഷ്യന് മനസ്സുള്ളതുപോലെ സര്&zwj;പ്പങ്ങള്&zwj;ക്ക് മനസ്സുണ്ടോ?
              <br />
              സത്യത്തില്&zwj; എനിക്ക് ഉത്തരം അറിയില്ല. എന്റെ നാട്ടില്&zwj;
              കാവുകള്&zwj; ഏറെയുണ്ട്. അതിലേറെ നാഗങ്ങളും. അവിടെ നടക്കുന്ന, ആരാധന
              സമ്പ്രദായങ്ങളില്&zwj;, ഒരിക്കല്&zwj; പോലും, നാഗങ്ങളുടെ സാന്നിധ്യം
              തടസ്സമായിട്ടില്ല. വളരെ ആഗ്രഹിച്ചു നോക്കിയാല്&zwj; എവിടെയും നാഗത്തെ
              കാണുകയും ചെയ്യാം .
            </p>

            <p>
              പലരും ചോദിക്കാറുണ്ട് ഞങ്ങൾ പ്രതിഷ്ഠിച്ച സർപ്പങ്ങലല്ല
              ഭൂമിയിലുള്ളത്, പണ്ടവിടം ഉപേക്ഷിച്ചുപോയ ബ്രാഹ്മണന്മാരുടെയോ 68ലെ
              ഭൂനിയമം വന്നപ്പോൾ കുടികിടപ്പായി കിട്ടിയ ഭൂമിയിലെ സർപ്പക്കാവുകളെ
              പരിപാലിക്കേണ്ടതുണ്ടോ?
              <br />
              ഇതിന്റെ ഉത്തരം ഏതെന്നാൽ സർപ്പം പൂച്ച പട്ടി ആന ഇവർ നാലുപേരും
              വീട്ടിൽ ഓമനിച്ചു വളർത്തുന്നുണ്ടെന്നു വിചാരിക്കുക, യജമാനൻ ഭൂമി
              ഉപേക്ഷിച്ചു പോയാൽ മേല്പറഞ്ഞ പട്ടിയും ആനയും വ്യക്തിബന്ധികൾ ആയതിനാൽ
              അവർ കൂടെ പോകുകയും സ്ഥല ബന്ധികളായ സർപ്പവും പൂച്ചയും അവിടെ തുടരുകയും
              ചെയ്യും. അതിനാൽ ആ സ്ഥലത്തു വന്നു പാർക്കുന്നവർ അവരെ നോക്കാൻ
              ബാധ്യസ്ഥരാണ്. കൂടാതെ ശപിക്കാനുള്ള കഴിവ് ദൈവങ്ങൾ ഇവർക്ക്
              നൽകിയിട്ടുണ്ട്, ആഗ്രഹങ്ങളുടെയും ദേഷ്യത്തിന്റെയും
              പ്രതീകങ്ങളായതിനാലാണ് ഇവർക്ക് ആ കഴിവ് ലഭിച്ചത്.
            </p>

            <p>
              ജാതകങ്ങളിലെ, സര്&zwj;പ്പ സ്ഥിതി പലപ്പോഴും അവിശ്വസനീയമായ ഒരു മു൯കാല
              ആരാധന ക്രമത്തിലേക്ക് നമുക്കറിയാ൯ പറ്റാത്ത ഒരു കാലത്തിലേക്ക്
              പലപ്പോഴും എന്നെ കൂട്ടി കൊണ്ടു പോയിട്ടുണ്ട്. മനസ്സിന്റെ ജിജ്ഞാസ
              കൊണ്ടു പലപ്പോഴും ഞാ൯ ഒരു ജാതകത്തിലെ സര്&zwj;പ്പ സ്ഥിതിയുടെ പുറകെ
              പോയപ്പോഴൊക്കെയും പണ്ട് എപ്പഴോ പ്രതാപത്തിലായിരുന്ന ഒരു കാവിന്റെ
              ശുഷ്കിച്ച അവസ്ഥ കാണാനായിട്ടുണ്ട്. അങ്ങോട്ടേയ്ക്ക് എന്നെ നയിച്ച
              ശക്തി ഏതാണ് എന്ന് ചിന്തിച്ചതില്&zwj; രണ്ടഭിപ്രായമെനിക്കില്ല നാഗ
              ദൈവങ്ങള്&zwj; തന്നെ.
            </p>

            <p>
              മറ്റുള്ളവരുടെ അഭിപ്രായങ്ങള്&zwj; എനിക്കറിയില്ല അതെന്നെ
              വിഷമിപ്പിക്കാറുമില്ല. അതുകൊണ്ടു തന്നെ പറയാം നാഗ ദൈവങ്ങള്&zwj;
              സത്യങ്ങളാണ്. നിത്യമായ സത്യം. അതുകൊണ്ട് സര്&zwj;പ്പം നമ്മോടു
              കാര്യങ്ങള്&zwj; പറയും. അതിനുള്ള തെളിവും തരും സത്യം. പക്ഷെ ആ
              അടുപ്പത്തിന് നമ്മുടെ ഭക്തിയുടെ ആഴം ഒരു ഘടകം ആണ്. നല്ല ഭക്തി
              അതായത്&zwnj; ഭഗവനോട് എത്ര മാത്രം താദ്ദാമ്യം പ്രാപിക്കുന്നുവോ
              അതനുസരിച്ച് ഈ സത്യങ്ങള്&zwj; ബോധ്യപ്പെടും.
            </p>

            <p>
              ജാതകത്തിലെ 6,8,12 ഭാവങ്ങളെ അനിഷ്ട ഭാവങ്ങള്&zwj; എന്ന്&zwj; പറയും.
              ഇതില്&zwj; 6 ലോ 12 ലോ നില്&zwj;ക്കുന്ന സര്&zwj;പ്പം,
              സര്&zwj;പ്പശാപത്തെ കാണിക്കുന്നു. ആ ശാപ കാരണം കണ്ടു പിടിക്കുകയും,
              പരിഹാരങ്ങള്&zwj; ചെയ്യേണ്ടതും അത്യാവശ്യമാണ്. അതിന് ,
              പ്രശ്നമാര്&zwj;ഗ്ഗത്തേയും കവിടിയേയും ആശ്രയിക്കണം.
            </p>

            <p>
              രാഹു ദോഷമുള്ളവര്&zwj;ക്ക്&zwnj;, പൊതുവെ, ചര്&zwj;മ്മ സംബന്ധ
              രോഗങ്ങള്&zwj; ഉണ്ടാവും. മരുന്നു കൊണ്ടു മാറിയില്ലെങ്കില്&zwj;,
              ജാതകം പരിശോധിച്ച്, പ്രായശ്ചിത്തങ്ങള്&zwj; ചെയ്തതിനു ശേഷം
              ചികിത്സിക്കുന്നതാവും ഉത്തമം. സര്&zwj;പ്പദോഷമുള്ളവര്&zwj;ക്ക്&zwnj;
              സന്താന ക്ലേശം ഉണ്ടാവുമെന്നതില്&zwj; തര്&zwj;ക്കം ഇല്ല. നല്ലൊരു
              ജ്യോതിഷിയെക്കണ്ടു പരിഹാരങ്ങള്&zwj; ചെയ്യണം. ഗര്&zwj;ഭ
              വിഷയത്തില്&zwj; ബീജ ദോഷവും, ക്ഷേത്ര ദോഷവും, പ്രധാനമാണല്ലോ. ഈ രണ്ടു
              ദോഷങ്ങളും പരിശോധിക്കുന്ന വേളയില്&zwj; അദൃശ്യമായ സര്&zwj;പ്പ
              സാന്നിധ്യം നമ്മുക്ക് കാണാം. സര്&zwj;പ്പ ദോഷമുള്ള, സ്ത്രീകളില്&zwj;
              ഉദരരോഗം, മാസമുറയിലെ കൃത്യതയില്ലായ്മ , യൂട്രസ് പ്രശ്നങ്ങള്&zwj;
              എന്നിവ നിരന്തരം ശല്യപ്പെടുത്തുന്നത് കാണാം.
            </p>

            <p>
              മേല്പ്പറഞ്ഞ രോഗങ്ങള്&zwj; എല്ലാം വായിക്കുമ്പോള്&zwj; തന്നെ
              സര്&zwj;പ്പദോഷത്തിന്റെ ഗൌരവം, അഥവാ സര്&zwj;പ്പശാപം എത്ര ഗൌരവമായാണ്
              മനുഷ്യനില്&zwj; പതിക്കുന്നത് എന്ന് മനസ്സിലാക്കാം.
            </p>

            <p>
              4 ഭാവത്തിലെ രാഹു, ഉന്നത വിദ്യാഭ്യാസം മുടക്കുക മാത്രമല്ല
              സ്വാഭാവത്തെ ദുഷിപ്പിക്കുകയും ചെയ്യുന്നു. മനസ്സിന്റെ കാരകനാണ്
              ചന്ദ്ര൯ . ആ ചന്ദ്രനുമായി സര്&zwj;പ്പം അടുത്താല്&zwj; ജാതക൯ വളഞ്ഞ
              വഴിയില്&zwj; മാത്രം ചിന്തിക്കും. പ്രസ്തുതയോഗം മനസ്സിന്റെ താളം
              തെറ്റിക്കും. തല തിരിഞ്ഞ ചിന്തകള്&zwj; എന്നു വിശേഷിപ്പിക്കാവുന്നവ ,
              യുക്തി വിരുദ്ധ തീരുമാനങ്ങള്&zwj; , വിഘടനവാദം എന്നിവ ജാതകന്റെ
              സ്വാഭാവത്തില്&zwj; രൂപപ്പെടും. അങ്ങനെയുള്ള ഒരു വ്യക്തി സ്വയം
              നശിക്കുകയും, തന്നോടു കൂടുന്നവരെ കൂടി നശിപ്പിക്കുകയും ചെയ്യും
              .അങ്ങനെ ഒരാളെ ജീവിത പങ്കാളിയായിക്കിട്ടിയാലോ ?
            </p>

            <p>
              പരിശുദ്ധിയുടെ പ്രതീകമാണ് സര്&zwj;പ്പം. അതുകൊണ്ട്- തന്നെയാണ്
              ദേവാദിദേവനായ മഹാദേവന്റെ കഴുത്തിലെ ആഭരണമായി സര്&zwj;പ്പം
              വിളങ്ങുന്നതും. ഈ പരിശുദ്ധിക്ക് കോട്ടം വരുന്ന ഒരു
              പ്രവര്&zwj;ത്തനങ്ങളും, അറിഞ്ഞോ, അറിയാതെയോ ചെയ്യാനിട വന്നാല്&zwj;
              സര്&zwj;പ്പ കോപം ഭവിക്കും.
            </p>

            <p>
              നിഷ്ഠയില്&zwj; അടിസ്ഥിതമാണ് ജീവിതം. ആ നിഷ്ഠയില്&zwj;,
              സര്&zwj;പ്പത്തിന്റെ പങ്ക് വളരെ വലുതും. ആയതിനാല്&zwj; സര്&zwj;പ്പ
              കോപം വരാതിരിക്കാ൯ ഈശ്വരനോട് പ്രാര്&zwj;ത്ഥിക്കുക. കാരണം
              തലമുറകളിലൂടെ പകരുന്ന ശാപമാണ് ഫലം. ജാതകത്തില്&zwj; 7 ആം
              ഭാവത്തില്&zwj; നില്&zwj;ക്കുന്ന സര്&zwj;പ്പം സൂചിപ്പിക്കുന്നത്, ആ
              വ്യക്തിയുടെ കഴിഞ്ഞ ജന്മത്തിലെ മരണം അസ്വാഭാവികം ആയിരുന്നു
              എന്നത്രേ.രാഹു ബാധാ സ്ഥാനത്ത് നിന്നാല്&zwj; സര്&zwj;പ്പദോഷം പറയാം.
            </p>

            <p>
              ഒരു ജാതകത്തിലെ 5 ആം ഭാവം കൊണ്ടാണ്, പൂര്&zwj;വ്വ
              ജന്മത്തെയും,സന്താനങ്ങളെയും പറ്റി ചിന്തിക്കുന്നത്. ഈ ഭാവത്തില്&zwj;
              സര്&zwj;പ്പ സ്ഥിതി വന്നാല്&zwj; പൂര്&zwj;വ്വ ജന്മത്തില്&zwj;
              സര്&zwj;പ്പം ലഗ്നത്തിലായിരുന്നു എന്നു മനസ്സിലാക്കണം. ആ
              സര്&zwj;പ്പത്തിന്റെ അവസ്ഥയറിഞ്ഞു വേണം പരിഹാരങ്ങള്&zwj;
              നിശ്ചയിക്കാൻ. സര്&zwj;പ്പത്തിന്റെ അവസ്ഥ എന്നതുകൊണ്ട്
              മനസ്സിലാക്കേണ്ടത്, പ്രസ്തുത സര്&zwj;പ്പത്തിന് വ്യാഴബന്ധം
              വന്നാല്&zwj; ഉത്തമ സര്&zwj;പ്പം (മണി നാഗം) എന്നും ചന്ദ്രബന്ധം
              വന്നാല്&zwj; അധമ സര്&zwj;പ്പം (കരിനാഗം) എന്നും, രാഹുവിന്&zwnj;
              ശനിബന്ധം വന്നാൽ കല്ലുരുട്ടി സർപ്പമെന്നും, ശുക്രബന്ധം വന്നാൽ
              പുള്ളുവസർപ്പമെന്നും, രാഹുവിന്&zwnj; രവിബന്ധം വന്നാൽ നാഗരാജാവും,
              രാഹുവിന്&zwnj; ചന്ദ്ര ശുക്രന്മാരുടെ പൂർണ്ണ ബന്ധം വന്നാൽ
              നാഗയക്ഷിയും രാഹുവിൽ ചന്ദ്ര കുജന്മാരുടെ ബന്ധംവന്നാൽ നാഗാചാമുണ്ടിയും
              എന്നീ വ്യത്യസ്ത ശാപങ്ങളാണെന്നു മനസ്സിലാക്കി വ്യത്യസ്ത
              വഴിപാകുടുകളിൽ പെട്ട സർപ്പബലി, പാൽപ്പായസ ഹോമം, രാഹുവിന്റെ നമസ്കാര
              വേദമന്ത്രം ഓം കയാനശ്ചിത്ര ആ ഭുവ ഭൂതി സദാവൃധ എന്ന് തുടങ്ങുന്ന
              ഋക്ക്&zwnj; രാഹുവിന്റെ ദിക്കായ തെക്കു പടിഞ്ഞാറ് ദിക്കു നോക്കി
              എട്ടുത്തവണ ചൊല്ലി സാഷ്ടാംഗം നമസ്ക്കരിക്കുക. ഉത്തമ
              സര്&zwj;പ്പദോഷത്തിന് സ്വര്&zwj;ണ്ണ നാഗ രൂപവും, അധമ സര്&zwj;പ്പ
              കോപത്തിന് വെള്ളി, ചെമ്പ്&zwnj; എന്നീ ലോഹത്തിലുള്ള നാഗരൂപവും,
              മുട്ടകളുമാണ് പരിഹാരം. അവ എത്ര എണ്ണം വേണം എന്നത്&zwnj; ഒരു
              ദൈവജ്ജനെക്കണ്ട് തീരുമാനിക്കുന്നതാണ് ഉത്തമം.
            </p>

            <p>
              രാഹു 4-ല്&zwj; നിന്നാല്&zwj; ചിത്ര കുടം കെട്ടിച്ച്, പ്രതിഷ്ഠ
              കര്&zwj;മ്മങ്ങള്&zwj; നടത്തേണ്ടതായി വരും. രാഹു ലഗ്നത്തിലോ 7 ലോ
              നിന്നാല്&zwj; നൂറും പാലും വഴിപാട് നടത്തണം. 12-ലെ
              സര്&zwj;പ്പദോഷത്തിന് പുള്ളുവ൯ പാട്ട്, കളം വരച്ച് സര്&zwj;പ്പം
              തുള്ളല്&zwj; മുതലായവ ചെയ്യിക്കണം.
            </p>

            <p>
              സര്&zwj;പ്പക്കാവ് നശിപ്പിക്കുക, അശുദ്ധിയാക്കുക, കാവിലെ
              മരങ്ങള്&zwj; മുറിക്കുക, പുറ്റ് നശിപ്പിക്കുക, മുട്ട നശിപ്പിക്കുക,
              സര്&zwj;പ്പകുഞ്ഞുങ്ങള്&zwj;ക്ക് നാശം വരുത്തുക എന്നിവയാണ് പ്രധാനമായ
              സര്&zwj;പ്പകോപകാരണങ്ങള്&zwj; . ഇവയിലേത് ദോഷമാണ് എന്ന്,
              മനസ്സിലാക്കാ൯ ഉത്തമനായ ഒരു ദൈവജ്ജന് മാത്രമേ കഴിയൂ. അങ്ങനെയുള്ള
              ദൈവജ്ജ൯ ദോഷങ്ങള്&zwj; കണ്ടുപിടിക്കുകയും, അതിനുള്ള പരിഹാരങ്ങള്&zwj;
              നിശ്ചയിക്കുകയും ചെയ്യും. ശാസ്ത്രത്തില്&zwj; ഓരോ ദോഷത്തിനും,
              പ്രത്യേകം പരിഹാരങ്ങള്&zwj; വ്യക്തമായി പറഞ്ഞിട്ടുമുണ്ട്.
              ജാതകത്തില്&zwj; എല്ലാ പാപഗ്രഹങ്ങളും കേന്ദ്രത്തില്&zwj;
              നില്&zwj;ക്കുന്നതിനെ സര്&zwj;പ്പയോഗം എന്നു പറയുന്നു. ജാതക൯
              നിര്&zwj;ധനനും, ദിനനും,സ്വാഭാവ ശുദ്ധിയില്ലാത്തവനുമായി ഭവിക്കും.
              വീടുവയ്ക്കുവാനായി ഉദ്ദേശിച്ച പുരയിടത്തിനെ 9 വീഥിയായി
              തിരിച്ചാല്&zwj;, അഞ്ചാമത്തെ വീഥിയെ സര്&zwj;പ്പവീഥിയെന്നു പറയുന്നു.
              ഗൃഹം ഈ വീഥിയില്&zwj; കയറിയാല്&zwj; സര്&zwj;പ്പഭയം ആണ് ഫലം.
            </p>

            <p>
              ഒരു കാര്യം ദയവായി മനസ്സിലാക്കുക. ഉണ്ട് എന്ന് പറയുന്ന ഒരു കാര്യം
              തെളിയിക്കേണ്ടുന്നതായ ആവശ്യം ഇല്ല. എന്നാല്&zwj; ഇല്ല എന്നു പറയുന്ന
              കാര്യം തെളിയിക്കേണ്ടതായി വരുന്നു. പലപ്പോഴും, അതു പറഞ്ഞ
              ആളിനായിരിക്കും ആ ബാധ്യത.
            </p>

            <p>
              സര്&zwj;പ്പതാപം മ൯ ജന്മം ഇവയെല്ലാം തന്നെ ബോധ്യപ്പെടാവുന്ന
              കാര്യങ്ങളാണ്. പക്ഷെ അതിനും ഒരു ഈശ്വരേശ്ച വേണം.
              തലമുറകള്&zwj;ക്ക്&zwnj; ശേഷവും നമ്മെത്തേടിയെത്തുന്ന സര്&zwj;പ്പം
              പോലെ -സര്&zwj;പ്പഃ സീരിതേ" എന്നാല്&zwj; സര്&zwj;പ്പം
              ഇഴയുന്നതെന്നും പറക്കുന്നുവെന്നും അര്&zwj;ത്ഥം. ആയിരം തലയുള്ള
              അനന്തന്&zwj;റെ തലയില്&zwj; ഭൂമി ഇരിയ്ക്കുന്നുവെന്ന്
              വിശ്വസിക്കുന്നു. അനന്തന്&zwj; അന്തമില്ലാത്ത ശക്തിയാകുന്നു.
              സര്&zwj;വ്വശക്തിയായ അനന്തമായ ശക്തിയാണത്. എല്ലായിടത്തും നിറഞ്ഞ്
              ശക്തിതരംഗങ്ങളായി ഇളകികൊണ്ടിരിയ്ക്കുന്നതാണ് അനന്തന്&zwj;.
            </p>

            <p>
              ഗോളങ്ങളുടെ അനോന്യമുള്ള ആകര്&zwj;ഷണശക്തി, പ്രകൃതിയുടെ ശക്തി,
              മനുഷ്യന്&zwj;റെ ശക്തി, ശബ്ദശക്തി, ഊര്&zwj;ജ്ജ ശക്തി, വായു ശക്തി,
              ഈശ്വരനില ശക്തി, ദേവ ബിംബങ്ങളില്&zwj; നിന്നുള്ള ശക്തി ഈ
              മഹാശക്തികളെല്ലാം തരംഗമാലകളായാണ് പ്രവഹിക്കുന്നത്. ദേവബിംബത്തിലെ
              ശക്തിതരംഗം അനുഗ്രഹകലകളായി ഭക്തനിലേയ്ക്ക് എത്തുന്നത് ഇഴയുന്ന
              സര്&zwj;പ്പത്തെപ്പോലെയാകുന്നു.
            </p>

            <p>
              പ്രപഞ്ചത്തില്&zwj; അലമാലകളെപ്പോലെ ശക്തിതരംഗങ്ങള്&zwj;
              അലയിളകിമറിയുന്നു. ആകാശഗോളങ്ങളേയും പ്രകൃതിയെ ആകെയും മനുഷ്യരെ ആകെയും
              സമസ്ത ജീവജാലങ്ങളേയും നേരിട്ട് ദര്&zwj;ശിയ്ക്കുവാന്&zwj; ആകാത്ത
              ശക്തിതരംഗങ്ങളാല്&zwj; അന്യോന്യം
              ആകര്&zwj;ഷിക്കപ്പെട്ടുകൊണ്ടിരിയ്ക്കുകയാണ്. ചിതറി വീഴുന്നവിധം
              പ്രപഞ്ചശക്തിയായ തരംഗമാലകളില്&zwj; തങ്ങി ആകാശഗോളങ്ങളോടൊപ്പം
              ഭൂഗോളവും നിലകൊള്ളുന്നു. ശക്തിതരംഗങ്ങളുടെ എണ്ണവും ബലവും ഒരുകാലത്തും
              നിര്&zwj;ണ്ണയിക്കുവാനാകുന്നതല്ല.
            </p>

            <p>
              ദേവന്മാരിലെല്ലാം നാഗബന്ധം കാണുന്നുണ്ട്. അത് ദേവനോടുള്ള
              പ്രപഞ്ചശക്തി ബന്ധത്തെ സൂചിപ്പിയ്ക്കുന്നതാണ്. ആയിരമായിരം
              പത്തികള്&zwj; വിരിച്ചു നില്&zwj;ക്കുന്ന നാഗത്തെപ്പോലെ ഇളകിപുളഞ്ഞ്
              മറിയുന്ന പ്രപഞ്ചമഹാശക്തിയില്&zwj; തങ്ങിനില്&zwj;ക്കുന്നു
              ആകാശഗോളങ്ങളും നക്ഷത്രജാലകങ്ങളും. അവയെ പ്രപഞ്ചത്തിലെ അപാരമായ
              ശക്തിബന്ധത്താല്&zwj; അന്ന്യോന്ന്യം
              ആകര്&zwj;ഷിച്ചുനിര്&zwj;ത്തിയിരിക്കുന്നു. തരംഗമാലകളുടെ
              ശിരസ്സില്&zwj; തങ്ങി ആകര്&zwj;ഷണത്തില്&zwj;പ്പെട്ട് ഭൂഗോളവും
              നിലകൊള്ളുകയാണ്. സങ്കല്പാതീതമായ ആകര്&zwj;ഷണശക്തികൊണ്ട് ഈ പ്രപഞ്ചം
              നിത്യമായിതീരുന്നു. ഇളകിമറിയുന്ന ശക്തി തരംഗങ്ങളില്ലെങ്കില്&zwj;
              ബ്രഹ്മാണ്ഡം ഉണ്ടാകുന്നതല്ല, നിലനില്&zwj;ക്കുകയുമില്ല. ഭാരതത്തിലെ
              ഋഷിമാര്&zwj; മുന്&zwj;കൂട്ടി ഇക്കാര്യങ്ങളെല്ലാം അറിഞ്ഞിരുന്നു.
            </p>

            <p>
              ഈ ശക്തി വിശേഷത്തെ മനുഷ്യര്&zwj; ദേവനായി ആരാധിയ്ക്കുന്നു.
              നാഗരൂപത്തില്&zwj; ദേവാലയങ്ങളുടെ അതിര്&zwj;ത്തിയ്ക്ക് പുറത്ത് കാവും
              നാഗദേവനും ഒക്കെയുണ്ട്. പഴയ തറവാടുകളിലും പ്രകൃതിയ്ക്കിണങ്ങിയ
              കാവുകളും കുളങ്ങളും ഉണ്ടായിരുന്നു. കേരളത്തനിമയും മണ്ണിന്&zwj;റെ
              മണവും മനോഹരമാക്കിത്തീര്&zwj;ക്കുന്ന സര്&zwj;പ്പത്താന്&zwj;പ്പാട്ടു
              സര്&zwj;പ്പംതുള്ളലും ഇന്ന് പഴംകഥകള്&zwj;. അപൂര്&zwj;വ്വമായി മാത്രം
              ഏതെങ്കിലും നാട്ടിന്&zwj;പുറങ്ങളില്&zwj; പാരമ്പര്യം
              തുടിയ്ക്കുന്നുണ്ടാകാം. പ്രകൃതിയുടെ നിലനില്&zwj;പ്പിന് കാവും
              കുളവുമൊക്കെ അനിവാര്യമാണ്. അത് സൗന്ദര്യമാകുന്നു.
            </p>

            <p>
              ഭാവനകളിലൂടെയും സങ്കല്പങ്ങളിലൂടെയും വിശ്വാസത്തിന്&zwj;റെ മണം
              പുരട്ടിയ ഈശ്വരസങ്കല്പങ്ങള്&zwj; കേരളത്തിനും ഭാരതത്തിനും
              അഴകൊഴുക്കിയിട്ടുണ്ട്. നമ്മുടെ പൂര്&zwj;വ്വികന്മാരായ
              ഗുരുക്കന്മാര്&zwj; മണ്ണിനെ മാത്രമല്ല പ്രകൃതിയേയും സ്നേഹിച്ചു.
              ആരാധിച്ചു, ഈശ്വരനായി കണ്ടു.
            </p>

            <p>
              നിങ്ങൾക്ക് എന്തെങ്കിലും ചോദ്യങ്ങളുണ്ടെങ്കിൽ ഞങ്ങളെ ബന്ധപ്പെടാൻ{" "}
              <Link
                to="/#contact-us"
                title="Get in touch with us"
                className="link_text"
              >
                ഇവിടെ ക്ലിക്കുചെയ്യുക
              </Link>
              .
            </p>
          </article>

          <aside className="article-sidebar">
            <AuthorInfo></AuthorInfo>
          </aside>
        </section>
      </section>
      <ServicesList cssClass="blog" />
      <BlogList cssClass="blog" />
    </Layout>
  )
}

export default AdministratorPage
